<template>
  <div class="note">
    <el-form
      label-position="top"
      hide-required-asterisk
      status-icon
      label-width="100px"
      class="demo-ruleForm"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="手机号" prop="phone">
        <el-input
          clearable
          placeholder="请输入手机号"
          v-model.trim="ruleForm.phone"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="smsCode">
        <div class="smsCode">
          <div>
            <el-input
              v-model.trim="ruleForm.smsCode"
              placeholder="请输入验证码"
              clearable
              autocomplete="off"
            ></el-input>
          </div>
          <div>
            <el-button
              plain
              type="primary"
              :disabled="disabledPhone || isBut"
              @click="() => getVerification()"
              >{{ butName }}</el-button
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="register_but">
          <el-button
            type="primary"
            @click="submitForm()"
            :disabled="disabledPhone || ruleForm.smsCode.trim().length === 0"
            >登录</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { postverificationCode, getLogin } from '../../../api/login.js';
import { getMenuTree } from '@/api/aidata.js';
import '../../../assets/css/customElement.less';
export default {
  name: '',
  data() {
    return {
      disabledPhone: true,
      butName: '获取验证码',
      isBut: false,
      butNum: 60,
      ruleForm: {
        phone: '',
        smsCode: '',
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: this.isValidPhoneNumber, trigger: 'change' },
        ],
        smsCode: [{ validator: this.validateVerification, trigger: 'change' }],
      },
    };
  },
  methods: {
    isValidPhoneNumber(rule, value, callback) {
      const pattern =
        /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!pattern.test(value)) {
        this.disabledPhone = true;
        callback('请输入正确手机号');
      } else {
        this.disabledPhone = false;
        callback();
      }
    },
    validateVerification(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } else {
        callback();
      }
    },
    // 获取验证码
    async getVerification() {
      const data = {
        phone: this.ruleForm.phone,
        smsType: 1,
      };
      const res = await postverificationCode(data);
      if (res.data.state === '0x0000') {
        this.$message({
          message: '验证码已发送',
          type: 'success',
        });
        this.countDown();
        this.isBut = true;
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning',
        });
      }
    },
    // 登录
    async submitForm() {
      const data = {
        loginType: 2,
        phone: this.ruleForm.phone,
        smsCode: this.ruleForm.smsCode,
      };
      const res = await getLogin(data);
      if (res.data.state === '0x0000' && !!res.data.data.token) {
        this.$message({
          message: '登录成功',
          type: 'success',
        });
        localStorage.setItem('tokenKey', res.data.data.token);
        localStorage.setItem('isAdministrator', res.data.data.isAdministrator);
        // 获取菜单管理权限
        this.getTreeList();
        // 登录跳转 跳转redirect or 首页 注意：redirect需要在来源页面进行 encodeURIComponent
        const redirect = this.$route.query.redirect;
        const needKey = this.$route.query.needKey;
        if (redirect) {
          let url = redirect;
          if (needKey) {
            url =
              redirect.indexOf('?') !== -1
                ? redirect + '&tokenKey=' + res.data.data.token
                : redirect + '?tokenKey=' + res.data.data.token;
          }
          window.location.href = url;
        } else {
          this.$router.push('/');
        }
      } else if (res.data.state === '0x0022') {
        this.$message({
          message: res.data.message,
          type: 'warning',
        });
        this.$router.push({
          path: '/logIn/registerAccount',
          query: {
            phone: this.ruleForm.phone,
            smsCode: this.ruleForm.smsCode,
          },
        });
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning',
        });
      }
    },
    // 倒计时
    countDown() {
      this.butName = '重新获取' + this.butNum + 's';
      this.butNum--;

      let trueval = setInterval(() => {
        this.butName = '重新获取' + this.butNum + 's';
        this.butNum--;
        if (this.butNum === 0) {
          this.isBut = false;
          this.butName = '获取验证码';
          this.butNum = 60;
          clearInterval(trueval);
        }
      }, 1000);
    },

    /**
     * @description: 获取菜单数据
     * @return {*}
     */
    getTreeList() {
      getMenuTree({
        menuPosition: 2,
      }).then(({ data }) => {
        let arrList = [];
        if ((data.state = '0x0000')) {
          data.data.forEach((item) => {
            arrList.push(item);
            if (item.children && item.children.length > 0) {
              arrList = [...arrList, ...item.children];
            }
          });
          // 处理返回的结果 res
          window.sessionStorage.setItem(
            'platformMenuTree',
            JSON.stringify(arrList),
          );
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.note {
  .smsCode {
    display: flex;
    div {
      height: 48px;
      .el-input {
        width: 339px;
        height: 48px;
        margin-right: 15px;
      }
      .el-button {
        width: 114px;
        padding-top: 16px;
        padding-bottom: 16px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .register_but {
    // margin-top: 24px;
    width: 468px;
    height: 48px;
    .el-button {
      width: 468px !important;
      height: 48px;
    }
  }
}

::v-deep .el-input__inner {
  height: 48px;
}
::v-deep .el-form-item__label {
  line-height: 20px;
  padding-bottom: 12px;
}
@media screen and (max-width: 1280px) {
  .note {
    .smsCode {
      display: flex;
      div {
        height: 48px;
        .el-input {
          width: 270px;
          height: 48px;
          margin-right: 12px;
        }
        .el-button {
          width: 92px;
          font-size: 14px;
        }
      }
    }
    .register_but {
      // margin-top: 24px;
      width: 374px;
      height: 48px;
      .el-button {
        width: 374px !important;
        height: 48px;
      }
    }
  }
}
@media screen and (max-width: 990px) {
  .note {
    .smsCode {
      display: flex;
      div {
        height: 48px;
        .el-input {
          // width: 220px;
          height: 48px;
          margin-right: 9.75px;
        }
        .el-button {
          // width: 75px;
        }
      }
    }
    .register_but {
      // margin-top: 24px;
      // width: 291px;
      height: 48px;
      .el-button {
        // width: 291px !important;
        height: 48px;
      }
    }
  }
}
</style>
