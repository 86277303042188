<!--
 * @Date: 2024-01-18 18:34:00
 * @LastEditTime: 2024-04-13 20:11:01
 * @Description: file content
-->
<template>
  <div class="register">
    <div class="radio">
      <span
        @click="clickSpan('note')"
        :class="{ radio_span: spanShow === 'note' }"
        >短信登录</span
      >
      <span
        @click="clickSpan('pass')"
        :class="{ radio_span: spanShow === 'pass' }"
        >密码登录</span
      >
    </div>
    <Note v-if="spanShow === 'note'"></Note>
    <pass v-else></pass>

    <div class="policy-content">
      登陆或注册即表示你同意 Aishipgo 的<el-button
        type="text"
        @click="jumpToProtocol(2)"
        >《用户协议》</el-button
      >的<el-button type="text" @click="jumpToProtocol(1)"
        >《隐私协议》</el-button
      >及<el-button type="text" @click="jumpToProtocol(3)"
        >《AI服务协议》</el-button
      >
    </div>
  </div>
</template>

<script>
import Note from './components/note.vue';
import pass from './components/passlog.vue';
import '../../assets/css/customElement.less';
export default {
  components: {
    Note,
    pass,
  },
  name: '',
  data() {
    return {
      spanShow: this.$route.query.tab || 'note',
      disabledPhone: true,
    };
  },
  methods: {
    clickSpan(is) {
      this.spanShow = is;
    },

    // 跳转至协议页面
    jumpToProtocol(activeId) {
      window.open(
        `https://aishipgo.com/#/protocol?active=${activeId}`,
        '_blank'
      );
    },
  },
};
</script>

<style lang="less" scoped>
.register {
  height: 468px;
  .radio {
    margin-bottom: 54px;
    span {
      margin-right: 56px;
      font-size: 24px;
      font-weight: 500;
      color: #4e5969;
      cursor: pointer;
      width: 96px;
    }
    .radio_span {
      padding-bottom: 5px;
      border-bottom: 2px solid #6c38e0;
      color: #6c38e0;
    }
  }

  .policy-content {
    max-width: 468px;
    line-height: 20px;
    font-size: 14px;
    color: #4e5969;
    margin-top: 18px;

    .el-button--text {
      margin: unset;
      line-height: 20px;
      font-size: 14px;
      padding: unset;
      color: #165dff;

      &:hover {
        color: #777aed;
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
</style>
