<template>
  <div class="pass">
    <el-form
      label-position="top"
      hide-required-asterisk
      status-icon
      label-width="100px"
      class="demo-ruleForm"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
    >
      <el-form-item label="手机号" prop="phone">
        <el-input
          placeholder="请输入手机号"
          clearable
          v-model.trim="ruleForm.phone"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model.trim="ruleForm.password"
          show-password
          type="password"
          placeholder="请输入密码"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button :disabled="disabled" type="primary" @click="submitForm()"
          >登录</el-button
        >
      </el-form-item>
      <div class="gopage">
        <span
          class="pointer"
          @click="() => this.$router.push('/logIn/registerAccount')"
          >去注册 <i class="el-icon-arrow-right"></i>
        </span>
        <span
          class="pointer"
          @click="
            () =>
              this.$router.push({
                path: '/logIn/forget',
                query: {
                  phone: ruleForm.phone,
                },
              })
          "
          >忘记密码<i class="el-icon-arrow-right"></i
        ></span>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getLogin } from '../../../api/login.js';
import { getMenuTree } from '@/api/aidata.js';
import '../../../assets/css/customElement.less';
export default {
  // props: ["isBut"],
  name: '',
  data() {
    return {
      disabledPhone: true,
      disabled: true,
      ruleForm: {
        phone: '',
        password: '',
        loginType: 1,
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: this.isValidPhoneNumber, trigger: 'change' },
        ],
        password: [{ validator: this.validateVerification, trigger: 'change' }],
      },
    };
  },
  methods: {
    // 登录
    async submitForm() {
      const res = await getLogin(this.ruleForm);
      if (res.data.state === '0x0000' && !!res.data.data.token) {
        this.$message({
          message: '登录成功',
          type: 'success',
        });
        localStorage.setItem('tokenKey', res.data.data.token);
        localStorage.setItem('isAdministrator', res.data.data.isAdministrator);
        this.getTreeList();
        const { redirect } = this.$route.query;
        const needKey = this.$route.query.needKey;
        if (redirect) {
          let url = redirect;
          if (needKey) {
            url =
              redirect.indexOf('?') !== -1
                ? redirect + '&tokenKey=' + res.data.data.token
                : redirect + '?tokenKey=' + res.data.data.token;
          }
          window.location.href = url;
        } else {
          this.$router.push('/');
        }
      } else {
        this.$message({
          message: res.data.message,
          type: 'warning',
        });
      }
    },

    isValidPhoneNumber(rule, value, callback) {
      const pattern =
        /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (!pattern.test(value)) {
        this.disabledPhone = true;
        callback('请输入正确手机号');
      } else {
        this.disabledPhone = false;
      }
    },
    validateVerification(rule, value, callback) {
      if (value === '') {
        this.disabled = true;
        callback(new Error('请输入密码'));
      } else {
        const allValuesNotEmpty = Object.values(this.ruleForm).every(
          (val) => val !== '',
        );
        console.log(allValuesNotEmpty, 'allValuesNotEmpty');
        if (allValuesNotEmpty && !this.disabledPhone) {
          this.disabled = false;
        }
        callback();
      }
    },
    /**
     * @description: 获取菜单数据
     * @return {*}
     */
    getTreeList() {
      getMenuTree({
        menuPosition: 2,
      }).then(({ data }) => {
        let arrList = [];
        if ((data.state = '0x0000')) {
          data.data.forEach((item) => {
            arrList.push(item);
            if (item.children && item.children.length > 0) {
              arrList = [...arrList, ...item.children];
            }
          });
          // 处理返回的结果 res
          window.sessionStorage.setItem(
            'platformMenuTree',
            JSON.stringify(arrList),
          );
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pass {
  .el-input {
    width: 468px;
    height: 48px;
  }
  .el-button {
    width: 468px !important;
    height: 48px;
  }
  .gopage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      cursor: pointer;
      color: #4e5969;
      font-size: 14px;
    }
  }
}
::v-deep .el-input__inner {
  height: 48px;
}
::v-deep .el-form-item__label {
  line-height: 20px;
  padding-bottom: 12px;
}
@media screen and (max-width: 1280px) {
  .el-input {
    width: 360px;
    height: 48px;
  }
  .el-button {
    width: 360px !important;
    height: 48px;
  }
}
</style>
